<template>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="isShowComingSoon"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Coming Soon</v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">Coming Soon</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                @click="toHome()"
                text
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
</template>

<script>
export default {
    data() {
        return {
            isShowComingSoon: true
        }
    },
    methods: {
        toHome() {
            this.$router.push('/');
        }
    }
}
</script>