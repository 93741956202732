<template>
    <div id="login">
        <loading ref="loading"></loading>
        <v-row style="margin:0px !important">
            <v-col class="login-left" cols="12" xs="12" sm="12" md="4">
                <div class="login-box-left p-10">
                    <div class="lbl-content grid justify-items-center">
                        <p class="text-xs text-center pt-5">
                            Selamat Datang di Dashboard Smartvillage D354 <br>
                            Untuk Masuk Ke Aplikasi <br> 
                            Silahkan Masukan Email dan Password
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col class="login-right" cols="12" xs="12" sm="12" md="8">
                <div class="lbr-bg">
                </div>
                <div class="login-box-right p-3 pr-4">
                    <div class="mt-5 flex justify-center">
                        <img style="width: 150px" src="/images/icons/logo.png" alt="Logo">
                    </div>
                    
                    <div class="flex justify-center" style="height:100%">
                        <div class="form-login">
                            <form action="#" @submit.prevent="login">
                                <h1 class="text-center">Login</h1>
                                <v-alert class="mt-10" v-if="error != null"
                                prominent
                                type="error"
                                >
                                    <v-row align="center">
                                        <v-col class="grow text-sm">
                                        {{error}}
                                        </v-col>
                                    </v-row>
                                </v-alert>
                                <div class="form-input">
                                    <label for="">Email</label>
                                    <input type="text" v-model="email">
                                </div>
                                <div class="form-input">
                                    <label for="">Password</label>
                                    <input type="password" v-model="pass">
                                </div>
                                <div class="flex justify-end pt-2">
                                    <!-- <a class="text-xs" href="#">Lupa Password</a> -->
                                </div>
                                <div class="form-input pt-5">
                                    <v-btn class="w-full" type="submit"
                                    color="cyan"
                                    dark
                                    >
                                        Login
                                    </v-btn>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import loading from '@/components/loading';

export default {
    name:'Login',
    components: {
        loading
    },
    data(){
        return{
            name:'exampleUser',
            email:'',
            pass:'',
            error:null
        }
    },
    computed: {
      ...mapGetters({
        // map `this.user` to `this.$store.getters.user`
        user: "user"
      })
    },
    methods:{
        register() {
            firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.pass)
            .then(data => {
                data.user
                    .updateProfile({
                    displayName: this.name
                })
                .then(() => {});
            })
            .catch(err => {
            this.error = err.message;
            console.log(err)
            });
        },
        cekLogin(){
            if(this.user.loggedIn === true){
                this.$router.push('/preview');
            }
        },
        login() {
            this.$refs.loading.isLoading = true;
            this.error = null;
            firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.pass)
            .then(data => {
                this.$refs.loading.isLoading = false;
                localStorage.setItem('loggedIn', true)
                this.$router.push('/preview');
            })
            .catch(err => {
                this.$refs.loading.isLoading = false;
                localStorage.setItem('loggedIn', false)
                this.error = err.message;
                console.log(err.message)
            });
        }

    },
    mounted(){
        this.cekLogin()
    }
    
}
</script>