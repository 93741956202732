<template>
    <div>
      <dialog-coming-soon></dialog-coming-soon>
    </div>
</template>

<script>
import DialogComingSoon from '@/components/dialogs/dialog-coming-soon'
export default {
    components: {
        DialogComingSoon
    },
    data() {
        return {}
    }
}
</script>