<template>
    <div v-if="show">
        <apexchart type="bar" :height="height" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props:{
        chartOptions: Object,
        series: Array
    },
    computed:{
       
    },
    data(){
        return{
           show:true,
           height:0,
        }
    },
    methods:{
        handleResize() {
            this.show = false;
            let getHeight = document.getElementById("acuan").offsetHeight;
            this.height = getHeight+190;
            
            setTimeout(() => {
                if(getHeight < 200){
                    setTimeout(()=>{
                        this.handleResize()
                    },200)
                }else{
                    if(this.$route.fullPath == '/pelayanan-surat'){
                        this.height = getHeight-70;
                    };
                    if(this.$route.fullPath == '/sft'){
                        this.height = getHeight-100;
                    };
                    if(this.$route.fullPath == '/umkm'){
                        this.height = getHeight+80;
                    };
                    this.show = true; 
                }
            }, 100)
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeMount() {
        this.$store.dispatch("initTheme");
    },
    async mounted(){
            this.handleResize();
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.chartOptions.theme.mode = 'dark';
            }else{
                this.chartOptions.theme.mode = 'light';
            };
            setTimeout(() => {
                this.show = true;
            }, 100)
        }
    }
}
</script>