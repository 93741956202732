<template>
    <div v-if="show"  id="bar-chart">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props:{
        chartOptions: Object,
        series: Array
    },
    data(){
        return{
            show:true
        }
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.chartOptions.theme.mode = 'dark';
            }else{
                this.chartOptions.theme.mode = 'light';
            };
            setTimeout(() => {
                this.show = true;
            }, 100)
        }
    }
}
</script>