<template>
    <div>
        <loading ref="loading"></loading>
        <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
            <v-row>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Aparatur Desa'" :persen="55" :content="formatNumber(dataSummary.tot_arapatur)+' Orang'" :color="'#67C4AF'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Desa'" :persen="45" :content="formatNumber(dataSummary.tot_desa)+' Desa'" :color="'#98A5AD'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Kepala Desa Laki Laki'" :persen="77" :content="formatNumber(dataSummary.tot_kepala_desa_laki_laki)+' Kepala Desa'" :color="'#3BAFDA'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Kepala Desa Perempuan'" :persen="12" :content="formatNumber(dataSummary.tot_kepala_desa_perempuan)+' Kepala Desa'" :color="'#F76197'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" sm="12" lg="12">
                    <v-card class="card pa-3" >
                        <h2 class="judul-nl font-weight-bold">Filter Data</h2>
                        <v-row class="mt-1">
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsKecamatan"
                                item-text="nama"
                                item-value="id_kec"
                                label="--Semua Kecamatan--"
                                solo outlined dense
                                v-model="id_kec"                
                                @change="getKelurahan()"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsDesa"
                                item-text="nama_kel"
                                item-value="nama_kel"
                                v-model="nama_kel"
                                label="--Semua Desa--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.minggu"
                                label="--Minggu Ke---"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.bulan"
                                label="--Januari--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.triwulan"
                                label="--Triwulan--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <div class="fp-button">
                                    <v-btn class="radius-25"
                                    color="#6CBCF1"
                                    dark
                                    v-on:click="showLoading()"
                                    >
                                        Filter
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3" sm="12" xs="12" lg="3">
                    <FusionChartPie v-if="dataSource.data.length"
                    title="Tingkat Pendidikan" 
                    type="pie3d"
                    :dataSource="dataSource" />
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12" lg="6">
                    <v-card style="height: 520px" class="card pa-3" id="chart-general2">
                        <h2 class="judul-nl font-weight-bold">Jenis Kelamin / Kecamatan</h2>
                        <ColumnChart v-if="seriesColumn.length" :series="seriesColumn" :chartOptions="chartOptionsColumn" />
                    </v-card>
                </v-col>    
                <v-col cols="12" md="3" sm="12" xs="12" lg="3">
                    <v-card class="card pa-3" id="acuan" >
                        <h2 class="judul-nl font-weight-bold">Aparatur Tertua</h2>
                        <div class="list-actor">
                            <div class="box-actor" v-for="x in older" :key="x.id">
                                <div class="box-actor-img">
                                    <img :src="x.photo" :alt="x.name">
                                </div>
                                <div class="box-actor-content">
                                    <h3 class="text-medium text-gray-600">
                                        {{x.name}}
                                    </h3>
                                    <p class="text-xs text-gray-500">
                                        {{x.address}}
                                    </p>
                                    <p class="text-xs text-gray-500">
                                        {{x.id}}
                                    </p>
                                    <h4 class="text-sm">
                                        {{x.age}} Tahun
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </v-card>
                    <v-card class="card pa-3" >
                        <h2 class="judul-nl font-weight-bold">Aparatur Termuda</h2>
                        <div class="list-actor">
                            <div class="box-actor" v-for="x in younger" :key="x.id">
                                <div class="box-actor-img">
                                    <img :src="x.photo" :alt="x.name">
                                </div>
                                <div class="box-actor-content">
                                    <h3 class="text-medium text-gray-600">
                                        {{x.name}}
                                    </h3>
                                    <p class="text-xs text-gray-500">
                                        {{x.address}}
                                    </p>
                                    <p class="text-xs text-gray-500">
                                        {{x.id}}
                                    </p>
                                    <h4 class="text-sm">
                                        {{x.age}} Tahun
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <v-card class="card pa-3" >
                        <v-row class="p-1">
                            <v-col cols="12" sm="12" md="6">
                                <h2 class="font-weight-bold flex justify-items-end">Daftar Aparatur Desa</h2>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="flex justify-end">
                                <div class="table-search">
                                    <input type="text" v-model="search" placeholder="Cari Pejabat" />
                                    <div class="icon-search">
                                        <v-icon color="#BEBEBE">mdi-magnify</v-icon>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-simple-table class="mt-2" :dark="$vuetify.theme.dark"
                                fixed-header
                            >
                                <template v-slot:default>
                                <thead>
                                    <tr class="head-table-sft">
                                        <th class="text-left">
                                            Foto
                                        </th>   
                                        <th class="text-left">
                                            NIK
                                        </th>   
                                        <th>
                                            Nama Aparatur
                                        </th>
                                        <th class="text-left">
                                            Nama Desa
                                        </th>                                    
                                        <th class="text-left">
                                            Jabatan
                                        </th>              
                                        <th class="text-left">
                                            Jenis Kelamin
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, idx) in dataFilter"
                                    :key="idx"
                                    >
                                        <td>
                                            <v-avatar>
                                                <img :src="item.link_foto">
                                            </v-avatar>
                                        </td>
                                        <td>{{item.nik}}</td>
                                        <td>
                                            <v-icon color="#2c98e0">mdi-account</v-icon> 
                                            {{item.nama.toUpperCase()}}
                                        </td>
                                        <td>{{item.nama_kel}}</td>
                                        <td>{{item.id_jabatan}}</td>
                                        <td>{{item.jenis_kelamin}}</td>
                                    </tr>
                                    <tr v-if="dataFilter.length < 1">
                                        <td colspan="8" class="text-center">Tidak Ada Data</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import FusionChartPie from '@/components/charts/FusionChartPie'
import StatisticRadial from '@/components/StatisticRadial'
import GeneralChart from '@/components/charts/GeneralChart'
import ColumnChart from '@/components/charts/ColumnCharts'
import loading from '@/components/loading';
export default {
    name:'PelayananSurat',
    components:{
        FusionChartPie,
        StatisticRadial,
        GeneralChart,
        ColumnChart,
        loading
    },
    data(){
        return {
            itemsKecamatan: [],
            itemsDesa: [],
            nama_kel: '',
            id_kec: '',
            search:'',
            older:[],
            younger:[],
            seriesPie: [44, 55, 13, 43, 22],
            dataSummary: {
                tot_arapatur: 0,
                tot_desa: 0,
                tot_kepala_desa_laki_laki: 0,
                tot_kepala_desa_perempuan: 0
            },
            chartOptionsPie: {
                chart: {
                width: 380,
                type: 'pie',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            itemsSelect: ['Data 1', 'Data 2', 'Data 3'],
            series: [
                {
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                },
                {
                    name: "Desktops",
                    data: [15, 55, 35, 21, 79, 62, 59, 41, 108]
                },
                {
                    name: "Desktops",
                    data: [55, 35, 75, 31, 49, 22, 89, 21, 98]
                },
                {
                    name: "Desktops",
                    data: [78, 55, 65, 31, 49, 62, 79, 61, 145]
                },
            ],
            dataTable:[],
            chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                    show: false,
                    tools: {
                        download: false,
                    }
                },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
          },
          seriesColumn: [],
          chartOptionsColumn: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                theme: {
                    mode: 'light', 
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Orang'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return val + " Orang"
                        }
                    }
                }
            },
            itemsFilter: {
                kec : [],
                desa: [],
                minggu: [],
                bulan: [],
                triwulan: []
            },
            dataSource : {
                chart: {
                    showvalues: "1",
                    showpercentintooltip: "0",
                    numberprefix: "$",
                    enablemultislicing: "1",
                    theme: "fusion"
                },
                data: []
            },
        }
    },
    computed:{
        dataFilter(){
            if(!this.search){
                return this.dataTable;
            }
            return this.dataTable.filter((data) => {
                return data.nama.toLowerCase().includes(this.search.toLowerCase())   
            })
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        getTP(){
          this.$http.get('/json/aparatur/tingkat-pendidikan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
            // console.log(res.data)
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getGender(){
          this.$http.get('/json/aparatur/jenis-kelamin.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesColumn = res.data.series;
            this.chartOptionsColumn.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getSummary(){
          this.$http.get('/json/aparatur/summary.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSummary = res.data.dataSummary;
            this.itemsFilter = res.data.dataFilter
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getRate(){
          this.$http.get('/json/aparatur/rate.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.older = res.data.older;
            this.younger = res.data.younger;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getAparatur(){
          this.$http.get('/json/aparatur/aparatur.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataTable = res.data.aparatur
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
    
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    created(){
        this.getSummary();
        this.getTP();
        this.getGender();
        this.getRate();
        this.getKecamatan();
        this.getAparatur();
    }
    
}
</script>
