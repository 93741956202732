<template>
    <v-dialog v-model="isLoading" fullscreen full-width>
        <v-container fill-height>
            <v-layout justify-center align-center>
            <v-progress-circular
                indeterminate
                color="primary">
            </v-progress-circular>
            </v-layout>
        </v-container>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return{
            isLoading: false
        }
    }
}
</script>