<template>
    <div class="con-sc " v-bind:class="{'bg-black': $vuetify.theme.dark,  'bg-white': !$vuetify.theme.dark}">
        <div class="image-sc">
            <img :src="image" :alt="title"/>
        </div>
        <v-card class="pa-1 b-none" >
            <v-card-text class="font-weight-bold isi-card">{{ title }}</v-card-text>
            <v-card-title class="font-weight-bold isi-card" :style="'color:'+color" >{{ content }}</v-card-title>
        </v-card>
    </div>
</template>
<script>
export default {
    props:{
        title: String,
        content: String,
        image: String,
        color: String,
    }
}
</script>
<style  scoped>
.con-sc{
    position: relative !important;
    border-radius: 4px;
}
.con-sc .image-sc{
    position: absolute !important;
    width: 100%;
    height:100%;
    bottom:0;
    right: 0;
}
.con-sc .image-sc img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 80%;
}

.b-none{
    background:none !important;
}
</style>