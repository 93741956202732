<template>
  <div id="penduduk">
      <loading ref="loading"></loading>
      <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="3" >
                  <StatisticCalc :title="'Jumlah Kecamatan'" image="/images/icons/kec.svg" :content="formatNumber(dataSummary.jum_kec)+' Kecamatan'" :color="'#028ADE'" />
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="3" >
                  <StatisticCalc :title="'Jumlah Desa'" image="/images/icons/desa.svg" :content="formatNumber(dataSummary.jum_karang_taruna)+' Desa'" :color="'#FFA101'" />
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="3" >
                  <StatisticCalc :title="'Jumlah Penduduk'" image="/images/icons/penduduk.svg" :content="formatNumber(dataSummary.jum_penduduk)+' Penduduk'" :color="'#53DF02'" />
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="3" >
                  <StatisticCalc :title="'Jumlah Karang Taruna'" image="/images/icons/karangtaruna.svg" :content="formatNumber(dataSummary.jum_karang_taruna)+' Karang Taruna'" :color="'#A897E1'" />
              </v-col>
              
              <v-col cols="12"  xs="12" sm="12" md="6" >
                  <v-card class="card pa-3" v-if="seriesPendidikan.length" >
                      <h2 class="judul font-weight-bold">Pekerjaan Penduduk</h2>
                      <!-- <ChartPolar :series="series" :chartOptions="chartOptions" /> -->
                      <apexchart type="polarArea" :options="chartOptions" :series="series"></apexchart>
                      
                  </v-card>
                  
                  <v-card class="card pa-3" id="chart-general" v-if="seriesPendidikan.length">
                      <h2 class="judul font-weight-bold">Pendidikan Penduduk</h2>
                      <ChartBar :series="seriesPendidikan" :chartOptions="chartOptionsPendidikan" />
                  </v-card>
              </v-col>
              
              <v-col cols="12" sm="12" md="6" xs="12">
                  <v-card class="card pa-3" >
                       <h2 class="judul font-weight-bold">Filter Data</h2>
                       <v-row class="mt-2">
                         <v-col cols="12" md="4" lg="3" sm="6">
                            <v-select class="radius-50" style="height:50px !important;"
                            :items="itemsKecamatan"
                            item-text="nama"
                            item-value="id_kec"
                            label="--Semua Kecamatan--"
                            solo
                            dense
                            v-model="id_kec"                
                            @change="getKelurahan()"
                            ></v-select>
                         </v-col>
                         <v-col cols="12" md="4" lg="3" sm="6">
                           <v-select class="radius-50" style="height:50px !important;"
                                :items="itemsDesa"
                                item-text="nama_kel"
                                item-value="nama_kel"
                                label="--Semua Desa--"
                                solo dense
                                ></v-select>
                         </v-col>
                         <v-col cols="12" md="4" lg="3" sm="6">
                           <v-select class="radius-50" style="height:50px !important;"
                                :items="itemsTahun"
                                label="--Tahun 2021--"
                                solo dense
                                ></v-select>
                         </v-col>
                         <v-col cols="12" md="4" lg="3" sm="6">
                           <div class="fp-button">
                                <v-btn
                                rounded
                                color="cyan"
                                dark
                                v-on:click="showLoading()"
                                >
                                    Filter
                                </v-btn>
                            </div>
                         </v-col>
                       </v-row>
                  </v-card>
                  <FusionPolar v-if="seriesPendidikan.length" title="Pendidikan Penduduk / Kecamatan" :dataSource="dataSource" />
              </v-col>

          </v-row>
    </div>
  </div>
</template>

<script>
import StatisticCalc from '@/components/StatisticCalc';
import ChartBar from '@/components/charts/BarChart';
import ChartPolar from '@/components/charts/PolarChart';
import FusionPolar from '@/components/charts/FusionPolar';
import loading from '@/components/loading';



export default {
    name: 'Penduduk',
    components: {
        StatisticCalc,
        ChartBar,
        ChartPolar,
        FusionPolar,
        loading
    },
    
    data(){
        return{
          dataSummary:{
            jum_kec:0,
            jum_desa:0,
            jum_penduduk:0,
            jum_karang_taruna:0,
          },
          heightBar: 0,
          widthBar: 0,
          heightCardBar:0,
          items: [
              { data: 'Kecamatan', jumlah : 123},
              { data: 'Desa', jumlah : 123},
              { data: 'Penduduk', jumlah : 123},
              { data: 'Karang Taruna', jumlah : 123},
          ],
          id_kec: '',
          id_kel: '',
          itemsKecamatan: [],
          itemsDesa: [],
          itemsTahun: [],
          series: [],
          chartOptions: {
            chart: {
              type: 'polarArea',
              height: 200
            },
            stroke: {
              colors: ['#fff']
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
              }
            }]
          },
          dataSource:{
            chart: {
                caption: false,
                subcaption: false,
                showPlotBorder: '1',
                baseFontColor: "ffffff",
                tooltipcolor: "000000",
                innerRadius: "70",
                theme: "fusion"
            },
            data:[]
          },
          seriesPendidikan: [],
          chartOptionsPendidikan: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                  show: false,
                  tools: {
                      download: false,
                  }
              },
            },
            theme: {
                mode: 'light', 
            },
            plotOptions: {
              bar: {
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
          },
          
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        handleResize() {
            let getHeight = document.getElementById("bar-chart").offsetHeight;
            let getWidth = document.getElementById("bar-chart").offsetWidth;
            this.heightBar = getHeight;
            this.widthBar = getWidth;
            this.heightCardBar = getHeight+50;
        },
        getPekerjaan(){
          this.$http.get('/json/penduduk/pekerjaan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            let data = res.data;
            this.series = data.series;
            this.chartOptions.labels = data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getSummary(){
          this.$http.get('/json/penduduk/summary.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSummary = res.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPendidikan(){
          this.$http.get('/json/penduduk/pendidikan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.chartOptionsPendidikan.xaxis.categories = res.data.labels;
            this.seriesPendidikan = res.data.series;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPenduduk(){
          this.$http.get('/json/penduduk/pendidikan-perkecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
            // this.itemsKecamatan = res.data.itemsSelect.kec;
            // this.itemsDesa = res.data.itemsSelect.desa;
            // this.itemsTahun = res.data.itemsSelect.tahun;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPendidikanPerkecamatan(){
          this.$http.get('/json/penduduk/pendidikan-perkecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
            this.itemsKecamatan = res.data.itemsSelect.kec;
            this.itemsDesa = res.data.itemsSelect.desa;
            this.itemsTahun = res.data.itemsSelect.tahun;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    created(){
      window.addEventListener('resize', this.handleResize);
      this.getPekerjaan();
      this.getPendidikan();
      this.getSummary();
      this.getPenduduk();
      this.getPendidikanPerkecamatan();
      this.getKecamatan();

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeMount() {
        this.$store.dispatch("initTheme");
    },
    mounted(){
      this.handleResize();
    },
}
</script>
