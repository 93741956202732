<template>
    <div>
        <loading ref="loading"></loading>
        <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" >
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                            <FusionChart
                            title="Grafik Pendapatan/Bidang" 
                            :type="type"
                            dataformat="'json'"
                            :dataSource="dataSource" />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="p-6" id="chart-general2"> 
                            <v-row>
                                <v-card class="card pa-3" >
                                    <h2 class="judul font-weight-bold">Filter Data</h2>
                                    <v-row class="mt-2">
                                        <v-col cols="12" md="5" sm="5">
                                            <div class="filter-siskudes-left">
                                                <v-select class="radius-25"
                                                :items="itemsKecamatan"
                                                item-text="nama"
                                                item-value="id_kec"
                                                v-model="id_kec"
                                                @change="getKelurahan()"
                                                label="--Semua Kecamatan--"
                                                solo outlined dense
                                                ></v-select>
                                            </div>
                                            <div class="filter-siskudes-left">
                                                <v-select class="radius-25"
                                                :items="itemsDesa"
                                                item-text="nama_kel"
                                                item-value="nama_kel"
                                                v-model="nama_kel"
                                                label="--Semua Desa--"
                                                solo outlined dense
                                                ></v-select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="7" sm="7">
                                            <div>
                                                <div class="filter-siskudes-right">
                                                    <v-select class="radius-25"
                                                    :items="itemsFilter.hari"
                                                    label="--Hari Ini--"
                                                    solo outlined dense
                                                    ></v-select>
                                                </div>
                                                <div class="filter-siskudes-right">
                                                    <v-select class="radius-25"
                                                    :items="itemsFilter.bulan"
                                                    label="--Semua Bulan Ini--"
                                                    solo outlined dense
                                                    ></v-select>
                                                </div>
                                                <div class="filter-siskudes-right">
                                                    <v-select class="radius-25"
                                                    :items="itemsFilter.triwulan"
                                                    label="--Triwulan--"
                                                    solo outlined dense
                                                    ></v-select>
                                                </div>
                                            </div>
                                            <div>
                                                <v-btn class="w-full"
                                                color="#6CBCF1"
                                                dark
                                                v-on:click="showLoading()"
                                                >
                                                    Filter
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-row>
                            <v-row class="mt-5"> 
                                <div class="half-section">
                                    <v-card style="height: 153px" class="card pa-3" >
                                        <h2 class="judul font-weight-bold">Total Penyerapan</h2>
                                        <h3 class="justify-center flex font-medium leading-10 pl-0 pt-5 pb-5" style="color:#6CBCF1">
                                            <div class="text-sm">Rp. &nbsp;</div> 
                                            <div class="text-xl mtext-base">{{formatNumber(dataIncome.penyerapan)}},-</div>
                                        </h3>
                                    </v-card>
                                </div>
                                <div class="half-section">
                                    <v-card style="height: 153px" class="card pa-3" >
                                        <h2 class="judul font-weight-bold">Total Pendapatan</h2>
                                        <h3 class="flex justify-center font-medium leading-10 pl-0 pt-5 pb-5" style="color:#6CBCF1">
                                            <div class="text-sm">Rp. &nbsp;</div> 
                                            <div class="text-xl mtext-base">{{formatNumber(dataIncome.pendapatan)}},-</div>
                                        </h3>
                                    </v-card>
                                </div>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                            <v-card class="card pa-3" >
                                <h2 class="judul font-weight-bold">Grafik Pengeluaran/Bidang</h2>
                                <ColumnChart v-if="seriesColumn.length" :series="seriesColumn" :chartOptions="chartOptionsColumn" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >
                    <v-card class="card pa-3" >
                        <h2 class="judul font-weight-bold">Pendapatan vs Penyerapan / Bidang</h2>
                        <GeneralChart type="bar" :height="300" :series="seriesBar" :chartOptions="chartOptionsBar" />
                    </v-card>
                    <v-card class="card p-5" style="margin-top: 50px; height: 415px" v-for="x in articles" :key="x.slug">
                        <!-- header -->
                        <div class="flex justify-between">
                            <div class="flex items-center">
                                <img :src="x.image" :alt="x.slug">
                                <h2 class="ml-2 my-4 color-blue">
                                    {{x.title}}
                                </h2>
                            </div>
                            <div class="flex justify-end items-center italic text-sm text-gray-500">
                                {{x.time}}
                            </div>
                        </div>
                        <!-- content -->
                        <div class="my-4 w-full mt-2" v-html="x.desc">
                            
                        </div>
                        <div class="my-4 w-full flex justify-end">
                            <!-- <a class="color-blue text-sm font-semibold" href="#">SHARE</a> -->
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-card class="card pa-3 relative">
                        <h2 class="judul font-weight-bold">Detail Realisasi kegiatan Desa</h2>    
                        <div class="absolute right-0 top-2">
                            <v-icon color="#D4D5DA">mdi-help-circle</v-icon>
                        </div>                   
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" v-for="(item,idx) in headers" :key="idx">
                                        {{item.text}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in dataTable"
                                :key="item.kec"
                                >
                                    <td>{{ item.kec }}</td>
                                    <td>{{ item.desa }}</td>
                                    <td>
                                        <p class="p-0 m-0 mb-1" v-for="x in item.sa" :key="x">
                                            {{x}}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="p-0 m-0 mb-1" v-for="x in item.nominal" :key="x">
                                            {{x}}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="p-0 m-0 mb-1" v-for="x in item.jml_kegiatan" :key="x">
                                            {{x}}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import FusionChart from '@/components/charts/FusionChartPie2'
import GeneralChart from '@/components/charts/GeneralChart'
import BarChart from '@/components/charts/BarChart'
import ColumnChart from '@/components/charts/BarChart'
import loading from '@/components/loading';
export default {
    name:'Siskudes',
    components:{
        FusionChart,
        GeneralChart,
        BarChart,
        ColumnChart,
        loading
    },
    data(){
        return {
            itemsKecamatan: [],
            itemsDesa: [],
            id_kec: '',
            nama_kel: '',
            itemsFilter: {
                kec:[],
                desa:[],
                hari:[],
                bulan:[],
                triwulan:[]
            },
            dataSource : {
                chart: {
                    showvalues: "1",
                    showpercentintooltip: "0",
                    numberprefix: "$",
                    enablemultislicing: "1",
                    theme: "fusion"
                },
                data: []
            },
            type: "pie3d",
            seriesBar: [],
            chartOptionsBar: {
                chart: {
                    type: 'bar',
                    height: 500,
                    stacked: true,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                colors: ['#008FFB', '#FF4560'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '80%',
                    
                    },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                width: 1,
                colors: ["#fff"]
                },
                
                grid: {
                xaxis: {
                    lines: {
                    show: false
                    }
                }
                },
                yaxis: {
                min: -5,
                max: 5,
                title: {
                    // text: 'Age',
                },
                },
                tooltip: {
                shared: false,
                x: {
                    formatter: function (val) {
                    return val
                    }
                },
                y: {
                    formatter: function (val) {
                    return Math.abs(val) + "%"
                    }
                }
                },
                xaxis: {
                categories: ['85+', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59', '50-54',
                    '45-49', '40-44', '35-39', '30-34', '25-29', '20-24', '15-19', '10-14', '5-9',
                    '0-4'
                ],
                title: {
                    text: 'Percent'
                },
                labels: {
                    formatter: function (val) {
                    return Math.abs(Math.round(val)) + "%"
                    }
                }
                },
            },
            articles:[],
            seriesColumn: [],
            dataIncome:{
                pendapatan:0,
                penyerapan:0
            },
            chartOptionsColumn: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                theme: {
                    mode: 'light', 
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: ''
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return val
                        }
                    }
                }
            },
            headers: [
                {
                    text: 'Kecamatan',
                    value: 'kec',
                },
                { text: 'Desa', value: 'desa' },
                { text: 'Sumber Anggaran', value: 'sa' },
                { text: 'Nominal', value: 'nominal' },
                { text: 'Jumlah Kegiatan', value: 'jum_kegiatan' },
            ],
            dataTable: [],
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        getFilter(){
          this.$http.get('/json/siskudes/filter.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsFilter = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getIncome(){
          this.$http.get('/json/siskudes/income.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataIncome = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getJenis(){
          this.$http.get('/json/siskudes/pengajuan-jenis.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getVs(){
          this.$http.get('/json/siskudes/pendapatan-penyerapan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesBar = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPB(){
          this.$http.get('/json/siskudes/pengeluaran-bidang.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesColumn = res.data.series;
            this.chartOptionsColumn.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getArticle(){
          this.$http.get('/json/siskudes/article.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.articles = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getTable(){
          this.$http.get('/json/siskudes/table.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataTable = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    created(){
        this.getFilter();
        this.getIncome();
        this.getJenis();
        this.getVs();
        this.getPB();
        this.getArticle();
        this.getTable();
        this.getKecamatan();
    }
}
</script>