/*=========================================================================================
  File Name: moduleEcommerceState.js
  Description: Ecommerce Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

/* eslint-disable */
import Vue from 'vue'
import storage from 'vue-ls'

const options = {
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

Vue.use(storage, options);

export default {
    dark : Vue.ls.get('mode') || false,
}
/* eslint-enable */
