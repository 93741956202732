<template>
    <div v-if="show">
        <v-card class="card pa-3" :style="'position:relative;height:'+heightCard+';width:100%'">
            <h2 class="judul font-weight-bold">{{title}}</h2>
            <fusioncharts
                type="sunburst"
                width="100%"
                :height="height"
                :dataSource="dataSource"
                >
            </fusioncharts>
        </v-card>
    </div>
</template>
<script>
export default {
    props:{
        title:String,
        dataSource: Object
    },
    computed:{
       
    },
    data(){
        return{
           show:true,
           width:0,
           height:0,
           heightCard:0,
        }
    },
    methods:{
        handleResize() {
            this.show = false;
            let getHeight = '600'
            let getWidth = '500'
            this.heightCard = '722px'
            if(this.$route.fullPath != '/umkm'){
                getHeight = document.getElementById("chart-general").offsetHeight;
                getWidth = document.getElementById("chart-general").offsetWidth;
                this.height = getHeight;
                this.heightCard = getHeight+465+'px';
            }
            // let getHeight = 500;
            // let getWidth = 500;
            this.height = 650;
            // this.heightCard = getHeight+100;
            this.width = getWidth;
            setTimeout(() => {
                this.show = true;
            }, 100)
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeMount() {
        this.$store.dispatch("initTheme");
    },
    mounted(){
        this.handleResize();
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.dataSource.chart.theme = 'candy';
            }else{
                this.dataSource.chart.theme = 'fusion';
            };
            setTimeout(() => {
                this.handleResize();
                this.show = true;
            }, 100)
        }
    }
}
</script>