/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
const actions = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  changeDarkMode ({ commit }, item) {
    commit('CHANGE_DARK_MODE', item)
  },
  initTheme({ commit }) {
      const cachedTheme = localStorage.theme ? localStorage.theme : false;
      //  `true` if the user has set theme to `dark` on browser/OS
      const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (cachedTheme)
          commit('SET_THEME', cachedTheme)
      else if (userPrefersDark)
          commit('SET_THEME', 'dark')
      else
          commit('SET_THEME', 'light')
  },
  toggleTheme({ commit }) {

      switch (localStorage.theme) {
            case 'light':
                commit('SET_THEME', 'dark')
                break;

            default:
                commit('SET_THEME', 'light')
                break;
        }
  },
  toggleMnav({ commit }, item) {
    // console.log(item)
    commit('SET_MNAV', item)
  },
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    localStorage.setItem('loggedIn', user !== null)
    if (user != null) {
      commit("SET_USER", {
        displayName: user.displayName,
        email: user.email
      });
    } else {
      commit("SET_USER", null);
    }
  }

  // // VxAutoSuggest
  // updateStarredPage ({ commit }, payload) {
  //   commit('UPDATE_STARRED_PAGE', payload)
  // },

  // // The Navbar
  // arrangeStarredPagesLimited ({ commit }, list) {
  //   commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  // },
  // arrangeStarredPagesMore ({ commit }, list) {
  //   commit('ARRANGE_STARRED_PAGES_MORE', list)
  // },

  // // /////////////////////////////////////////////
  // // UI
  // // /////////////////////////////////////////////

  // toggleContentOverlay ({ commit }) {
  //   commit('TOGGLE_CONTENT_OVERLAY')
  // },
  // updateTheme ({ commit }, val) {
  //   commit('UPDATE_THEME', val)
  // },

  // // /////////////////////////////////////////////
  // // User/Account
  // // /////////////////////////////////////////////

  // updateUserInfo ({ commit }, payload) {
  //   commit('UPDATE_USER_INFO', payload)
  // },
  // updateUserRole ({ dispatch }, payload) {
  //   // Change client side
  //   payload.aclChangeRole(payload.userRole)

  //   // Make API call to server for changing role

  //   // Change userInfo in localStorage and store
  //   dispatch('updateUserInfo', {userRole: payload.userRole})
  // }
}

export default actions
