<template>
    <div>
        <loading ref="loading"></loading>
        <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" >
                        <FusionPolar title="Jenis dan Kategori UMKM" :dataSource="dataSource" />
                        <v-card style="height: 280px" class="card pa-3 relative" id="acuan">
                            <h2 class="judul-ln font-weight-bold">Produk Terpopuler</h2>
                            <v-row class="mt-5">
                                <v-col cols="6" md="3" sm="6" lg="3" v-for="data in dataPopuler" :key="data.name">
                                    <div class="flex justify-center items-start">
                                        <img :src="data.image" class="rounded-full h-24 w-24" :alt="data.name">
                                    </div>
                                    <div class="flex justify-center">
                                        <img :src="getCrown(data.rate)" :alt="data.rate">
                                        <h3 class="text-xl text-center ml-2 mt-1 md:text-sm sm:text-sm">
                                            {{data.name}}
                                        </h3>
                                    </div>
                                    <div class="flex justify-center items-center mt-1">
                                        <v-icon color="#9E9E9E">
                                            mdi-eye
                                        </v-icon>
                                        <h4 class="ml-1 text-sm text-gray-500">
                                            {{formatNumber(data.views)}} views
                                        </h4>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" >
                        <v-row>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Jumlah UMKM
                                    </h2>
                                    <div class="w-full pt-10 grid justify-items-center items-center">
                                       <v-icon color="#6CBCF1" x-large>
                                           mdi-store
                                       </v-icon>
                                       <h3 class="text-center text-3xl text-blue-500">
                                           {{formatNumber(dataSummary.jumlah_umkm)}}<br>UMKM
                                       </h3>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Jumlah Jenis
                                    </h2>
                                    <div class="w-full pt-10 grid justify-items-center items-center">
                                       <v-icon color="#F76197" x-large>
                                           mdi-view-dashboard-variant
                                       </v-icon>
                                       <h3 class="text-center text-3xl text-red-500">
                                           {{formatNumber(dataSummary.jumlah_jenis)}}<br>Jenis
                                       </h3>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Jumlah Kategori
                                    </h2>
                                    <div class="w-full pt-10 grid justify-items-center items-center">
                                       <v-icon color="#67C4AF" x-large>
                                           mdi-shape
                                       </v-icon>
                                       <h3 class="text-center text-3xl text-green-500">
                                           {{formatNumber(dataSummary.jumlah_kategori)}}<br>Kategori
                                       </h3>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Jumlah UMKM Barang
                                    </h2>
                                    <div class="w-full pt-10 grid justify-items-center items-center">
                                       <v-icon color="#F76197" x-large>
                                           mdi-chart-areaspline
                                       </v-icon>
                                       <h3 class="text-center text-3xl text-red-500">
                                           {{formatNumber(dataSummary.jumlah_populer)}}<br>Produk
                                       </h3>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Jumlah UMKM Jasa
                                    </h2>
                                    <div class="w-full pt-10 grid justify-items-center items-center">
                                       <v-icon color="#707070" x-large>
                                           mdi-account-multiple
                                       </v-icon>
                                       <h3 class="text-center text-3xl text-gray-500">
                                           {{formatNumber(dataSummary.jumlah_jasa)}}<br>Jasa
                                       </h3>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="4">
                                <v-card class="card pa-3 h-64" >
                                    <h2 class="judul font-weight-bold">
                                        Filter Data
                                    </h2>
                                   <div class="w-full pt-5">
                                        <v-select class="radius-50"
                                        :items="itemsKecamatan"
                                        item-text="nama"
                                        item-value="id_kec"
                                        v-model="id_kec"
                                        @change="getKelurahan()"
                                        label="--Semua Kecamatan--"
                                        solo
                                        dense
                                        ></v-select>
                                        <v-select class="radius-50"
                                        :items="itemsDesa"
                                        item-text="nama_kel"
                                        item-value="nama_kel"
                                        v-model="nama_kel"
                                        label="--Semua Desa--"
                                        solo
                                        dense
                                        ></v-select>
                                        <div class="fp-button">
                                            <v-btn
                                            rounded
                                            color="cyan"
                                            dark
                                            v-on:click="showLoading()"
                                            >
                                                Filter
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-card class="card pa-3">
                                    <h2 class="judul font-weight-bold">
                                        Statistik Jumlah UMKM / Kecamatan
                                    </h2>
                                    <!-- <ColumnChart v-if="seriesColumn.length" :series="seriesColumn" :chartOptions="chartOptionsColumn" /> -->
                                    <div id="chart">
                                        <apexchart type="bar" height="350"  v-if="seriesColumn.length" :series="seriesColumn" :options="chartOptionsColumn"></apexchart>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
        </div>
    </div>
</template>
<script>
import FusionPolar from '@/components/charts/FusionPolar';
import ColumnChart from '@/components/charts/ColumnCharts'
import loading from '@/components/loading';

export default {
    name:'UMKM',
    components:{
        FusionPolar,
        ColumnChart,
        loading
    },
    data(){
        return {
            itemsKecamatan: [],
            itemsDesa: [],
            id_kec: '',
            nama_kel: '',
            dataSource:{
              chart: {
                caption: false,
                subcaption: false,
                showPlotBorder: "1",
                baseFontColor: "ffffff",
                tooltipcolor: "000000",
                innerRadius: "70",
                theme: "fusion"
              },
              data:[]
            },
            dataSummary:{
              jumlah_umkm: 57677,
              jumlah_jenis: 784895,
              jumlah_kategori: 876873,
              jumlah_populer: 723673,
              jumlah_jasa: 875938
            },
            itemsFilter:{
              kec:[],
              desa:[]
            },
            dataPopuler:[],
            seriesColumn: [],
            chartOptionsColumn: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false,
                            tools: {
                                download: false,
                            }
                        },
                    },
                    
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                            width: 200
                            },
                            legend: {
                            position: 'bottom'
                            }
                        }
                    }],
                    theme: {
                        mode: 'light', 
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: '$ (thousands)'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                },
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        getCrown(val){
            let color = 'gold';
            switch (val) {
                case 'gold':
                    color = "/images/icons/crown-gold.svg";
                    break;
                case 'silver':
                    color = "/images/icons/crown-silver.svg";
                    break;
                case 'bronze':
                    color = "/images/icons/crown-bronze.svg";
                default: 
                    color = "/images/icons/crown-bronze.svg";
            }
            return color;
        },
        getJenis(){
          this.$http.get('/json/umkm/jenis-kategori.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getSummary(){
          this.$http.get('/json/umkm/summary.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSummary = res.data.dataSummary;
            this.itemsFilter = res.data.dataFilter;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getProduk(){
          this.$http.get('/json/umkm/produk-populer.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataPopuler = res.data.data;
          }, error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getUMKM(){
          this.$http.get('/json/umkm/jumlah-umkm.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesColumn = res.data.series;
            this.chartOptionsColumn.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    mounted(){
      this.getJenis();
      this.getSummary();
      this.getProduk()
      this.getUMKM()
      this.getKecamatan();
    }
    
}
</script>