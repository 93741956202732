<template>
    <div v-if="show" id="chart-general">
        <apexchart :type="type" :height="height" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props:{
        chartOptions: Object,
        series: Array,
        type: String,
        height: Number, 
    },
    computed:{
       
    },
    data(){
        return{
           show:true,
        }
    },
}
</script>