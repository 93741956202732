<template>
    <div v-if="show" class="flex justify-center">
        <apexchart type="polarArea" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props:{
        chartOptions: Object,
        series: Array
    },
    computed:{
       
    },
    data(){
        return{
           show:true
        }
    },
    mounted(){
        // console.log(this.$vuetify.theme.dark)
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.chartOptions.theme.mode = 'dark';
            }else{
                this.chartOptions.theme.mode = 'light';
            };
            setTimeout(() => {
                this.show = true;
            }, 100)
        }
    } 
}
</script>