<template>
    <div v-if="show">
        <apexchart type="radialBar" :height="size" :width="size" :options="chartOptions" :series="series"></apexchart>
        <h3 class="text-center" :style="'color:'+color">
            {{title}}
        </h3>
    </div>
</template>
<script>
export default {
    props:{
        chartOptions: Object,
        series: Array,
        color: String,
        title:String
    },
    data(){
        return{
            show:true,
            window: {
                width: 0,
                height: 0
            },
            size: 150,
        }
    },
    methods:{
        handleResize() {
            // this.show = false;
            // this.chartOptions.fill.colors.push(this.color)
            // this.window.width = window.innerWidth;
            // this.window.height = window.innerHeight;
                
            let getWidth = document.getElementById("radial-con").offsetWidth;
            let calc = getWidth/3;
            this.size = calc+50;
            // setTimeout(() => {
            //     this.show = true;
            // }, 100)
            setTimeout(() => {
                if(getWidth < 200){
                    this.handleResize()
                }
            }, 100)
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        // this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeMount() {
        this.$store.dispatch("initTheme");
    },
    mounted(){
        this.handleResize();
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.chartOptions.theme.mode = 'dark';
            }else{
                this.chartOptions.theme.mode = 'light';
            };
            setTimeout(() => {
                this.show = true;
            }, 100)
        }
    }
}
</script>