<template>
    <div class="con-sc " v-bind:class="{'bg-black': $vuetify.theme.dark,  'bg-white': !$vuetify.theme.dark}">
        <v-card class="flex justify-between" >
            <div class="m-0">
                <apexchart class="m-0 p-0" type="radialBar" height="100" width="100" :options="opt" :series="series"></apexchart>
            </div>
            <div class="grid justify-items-end p-2">
                <v-card-title class="text-xs font-weight-bold p-0 pl-2" :style="'color:'+color" >{{ content }}</v-card-title>
                <p class="text-right text-xs mb-0">{{ title }}</p>
            </div>
        </v-card>
    </div>
</template>
<script>
export default {
    props:{
        title: String,
        content: String,
        persen: Number,
        color: String
    },
    data(){
        return{
            series: [this.persen],
            opt: {
                chart: {
                    height: 75,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '35%',
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                show:false,
                                offsetY: 5,
                                fontSize: '14px'
                            }
                        },
                       
                    },
                    
                },
                fill: {
                    colors: [this.color]
                },
                labels: ['Cricket'],
            },
        }
    }
}
</script>
<style  scoped>
.con-sc{
    position: relative !important;
    border-radius: 4px;
}
.con-sc .image-sc{
    position: absolute !important;
    width: 100%;
    height:100%;
    bottom:0;
    right: 0;
}
.con-sc .image-sc img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 80%;
}

.b-none{
    background:none !important;
}
</style>