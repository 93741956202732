<template>
    <div>
        <loading ref="loading"></loading>
      <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" >
                    <v-card class="card pa-3" >
                        <h2 class="judulx font-weight-bold">Total Dana BLT vs Pencairan</h2>
                        <GeneralChart type="line" :series="seriesLine" :chartOptions="chartOptionsLine" :height="300" />
                    </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="8" >
                    <v-card style="height: 520px" class="card pa-3" >
                        <h2 class="judulx font-weight-bold">Grafik Kecamatan</h2>
                        <ColumnChart :series="series" :chartOptions="chartOptions" />
                    </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" id="acuan" >
                  <v-card class="card pa-3" >
                       <p class="judulx font-weight-bold">Filter Data</p>
                        <div class="filter-sft">
                            <div class="sft-box">
                                <v-select class="radius-25"
                                :items="tahap"
                                label="--Semua Tahap--"
                                solo outlined dense
                                ></v-select>
                            </div>
                           <div class="sft-box">
                                <v-select class="radius-25"
                                :items="itemsKecamatan"
                                item-text="nama"
                                item-value="id_kec"
                                v-model="id_kec"
                                @change="getKelurahan()"
                                label="--Semua Kecamatan--"
                                solo outlined dense
                                ></v-select>
                            </div>
                            <div class="sft-box">
                                <v-select class="radius-25"
                                :items="itemsDesa"
                                item-text="nama_kel"
                                item-value="nama_kel"
                                v-model="nama_kel"
                                label="--Semua Desa--"
                                solo outlined dense
                                ></v-select>
                            </div>
                            <div class="sft-box">
                                <v-select class="radius-25"
                                :items="itemsFilter.tahun"
                                label="--Tahun 2021--"
                                solo outlined dense
                                ></v-select>
                            </div>
                            <div class="sft-button">
                                <v-btn
                                color="#63CFFF"
                                dark
                                v-on:click="showLoading()"
                                >
                                    Filter
                                </v-btn>
                            </div>
                        </div>
                  </v-card>
                  <v-card class="card pa-3" >
                        <h2 class="judulx font-weight-bold">Data Bantuan Langsung Tunai</h2>
                        <div class="radial-con" id="radial-con" >
                            <div class="radial-box">
                                <RadialChart :series="radial1" color="#1F78B4" title="Total Dana BLT" :chartOptions="optionsRadial1" />
                            </div>
                            <div class="radial-box">
                                <RadialChart :series="radial2" color="#249204" title="Dana Cair" :chartOptions="optionsRadial2" />
                            </div>
                            <div class="radial-box">
                                <RadialChart :series="radial3" color="#FF0E0E" title="Dana Belum Cair" :chartOptions="optionsRadial3" />
                            </div>
                        </div>
                  </v-card> 
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12">
                  <v-card class="card pa-3" >
                    <div class="ctitle">
                        <h2 class="font-weight-bold">Kab. Cianjur</h2>
                        <div class="table-search">
                            <input type="text" v-model="search" placeholder="Cari Nama Kecamatan" />
                            <div class="icon-search">
                                <v-icon color="#BEBEBE">mdi-magnify</v-icon>
                            </div>
                        </div>
                    </div>
                    <v-simple-table class="mt-2" :dark="$vuetify.theme.dark"
                            fixed-header
                        >
                            <template v-slot:default>
                            <thead>
                                <tr class="head-table-sft">
                                    <th>
                                        Nomor 
                                    </th>
                                    <th class="text-left">
                                        Kecamatan
                                    </th>                                    
                                    <th class="text-left">
                                        Total Dana
                                    </th>                                    
                                    <th class="text-left">
                                        Dana Sudah Cair
                                    </th>                            
                                    <th class="text-left">
                                        Dana Belum Cair
                                    </th>
                                    <th class="text-left">
                                        Total Penerima
                                    </th>
                                    <th class="text-left">
                                        Sudah Cair
                                    </th>
                                    <th class="text-left">
                                        Belum Cair
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item, idx) in dataFilter"
                                :key="idx"
                                >
                                    <td>{{ idx + 1 }}</td>
                                    <td>
                                        <p class="pl-2 pb-0 mb-0 font-medium text-blue-400">{{item.kec}}</p>
                                        <v-progress-linear
                                        color="light-green darken-4"
                                        height="20"
                                        :value="item.progress"
                                        striped
                                        >
                                            <strong>{{ item.progress.toFixed(2) }}%</strong>
                                        </v-progress-linear>
                                    </td>
                                    <td><v-icon color="#2c98e0">mdi-cash</v-icon> Rp. {{formatNumber(item.dana_total)}}</td>
                                    <td><v-icon color="#308800">mdi-cash</v-icon> Rp. {{formatNumber(item.dana_cair)}}</td>
                                    <td><v-icon color="#ff2727">mdi-cash</v-icon> Rp. {{formatNumber(item.dana_belum_cair)}}</td>
                                    <td><v-icon color="#2c98e0">mdi-account</v-icon> {{formatNumber(item.total_penerima)}} Orang</td>
                                    <td><v-icon color="#308800">mdi-account</v-icon> {{formatNumber(item.sudah_diterima)}} Orang</td>
                                    <td><v-icon color="#ff2727">mdi-account</v-icon> {{formatNumber(item.belum_diterima)}} Orang</td>
                                </tr>
                                <tr v-if="dataFilter.length < 1">
                                    <td colspan="8" class="text-center">Tidak Ada Data</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
              </v-col>
            </v-row>
      </div>
    </div>
</template>
<script>
import ColumnChart from '@/components/charts/ColumnCharts'
import RadialChart from '@/components/charts/RadialChart'
import GeneralChart from '@/components/charts/GeneralChart'
import loading from '@/components/loading';
export default {
    name: 'sft',
    components:{
        ColumnChart,
        RadialChart,
        GeneralChart,
        loading
    },
    data(){
        return {
            tahap: [
                1, 2, 3, 4, 5, 6, 7, 8, 9
            ],
            itemsKecamatan: [],
            itemsDesa: [],
            id_kec: '',
            nama_kel: '',
            seriesLine: [],
            chartOptionsLine: {
                chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                curve: 'straight'
                },
                grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
                },
                xaxis: {
                categories: [],
                }
            },
            dataTable:[],
            search:'',
            itemsFilter: {
                kec:[],
                desa:[],
                tahun:[]
            },
            series: [],
            radial1: [],
            radial2: [],
            radial3: [],
            optionsRadial1: {
                chart: {
                    height: 400,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: 5,
                                fontSize: '18px'
                            }
                        },
                    },
                    
                },
                fill: {
                    colors: ['#1F78B4']
                },
                labels: ['Cricket'],
            },
            optionsRadial2: {
                chart: {
                    height: 400,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: 5,
                                fontSize: '18px'
                            }
                        },
                    },
                    
                },
                fill: {
                    colors: ['#249204']
                },
                labels: ['Cricket'],
            },
            optionsRadial3: {
                chart: {
                    height: 400,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: 5,
                                fontSize: '18px'
                            }
                        },
                    },
                    
                },
                fill: {
                    colors: ['#FF0E0E']
                },
                labels: ['Cricket'],
            },
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: '500px',
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                theme: {
                    mode: 'light', 
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return "$ " + val + " thousands"
                        }
                    }
                }
            },
        }
    },
    computed:{
        dataFilter(){
            if(!this.search){
                return this.dataTable;
            }
            return this.dataTable.filter((data) =>
                data.kec.includes(this.search)
            )
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        getDana(){
          this.$http.get('/json/sft/dana.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesLine = res.data.series;
            this.chartOptionsLine.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKec(){
          this.$http.get('/json/sft/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.series = res.data.series;
            this.chartOptions.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getFilter(){
          this.$http.get('/json/sft/filter.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsFilter = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getRadial(){
            this.radial1 = [];
            this.radial2 = [];
            this.radial3 = [];
            this.$http.get('/json/sft/blt-radial.json',{
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((res) => { 
                this.radial1.push(res.data.data.total);
                this.radial2.push(res.data.data.cair);
                this.radial3.push(res.data.data.belum_cair);
            }
            , error   => { 
                this.$notify({
                group: 'foo',
                title: 'Error',
                text: error.message
                });
            })
        },
        getTable(){
            this.$http.get('/json/sft/table.json',{
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((res) => { 
                this.dataTable = res.data.data;
            }
            , error   => { 
                this.$notify({
                group: 'foo',
                title: 'Error',
                text: error.message
                });
            })
        },
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    created(){
        this.getDana();
        this.getKec();
        this.getFilter();
        this.getRadial();
        this.getTable();
        this.getKecamatan();
    }
}
</script>