<template>
    <div v-if="show">
        <v-card class="card pa-3" :style="'position:relative;height:'+heightCard+'px;width:100%'">
            <h2 class="font-weight-bold">{{title}}</h2>
            <fusioncharts
                :type="type"
                width="100%"
                :height="height"
                :dataSource="dataSource"
                >
            </fusioncharts>
        </v-card>
    </div>
</template>
<script>
export default {
    props:{
        title:String,
        type: String,
        dataSource: Object
    },
    data(){
        return{
           show:true,
           width:0,
           height:0,
           heightCard:0,
        }
    },
    methods:{
        handleResize() {
            this.show = false;
            let getHeight = document.getElementById("chart-general2").offsetHeight;
            let getWidth = document.getElementById("chart-general2").offsetWidth;
            this.height = getHeight-60;
            this.heightCard = getHeight;
            this.width = getWidth;
            setTimeout(() => {
                if(getHeight < 200){
                    this.handleResize()
                }else{
                    this.show = true;
                }
            }, 100)
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeMount() {
        this.$store.dispatch("initTheme");
    },
    mounted(){
        this.handleResize();
    },
    watch: {
        '$vuetify.theme.dark': function() {
            this.show = false;
            if(this.$vuetify.theme.dark == true){
                this.dataSource.chart.theme = 'candy';
            }else{
                this.dataSource.chart.theme = 'fusion';
            };
            setTimeout(() => {
                this.handleResize();
                this.show = true;
            }, 100)
        }
    }
}
</script>