<template>
    <div>
        <loading ref="loading"></loading>
        <div class="konten-nobg" :class="{'konten': this.$vuetify.theme.dark  == false}" >
            <v-row>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Pengajuan Surat'" :persen="100" :content="formatNumber(dataSummary.tot_pengajuan)+' Surat'" :color="'#67C4AF'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Surat Disetujui'" :persen="61.50" :content="formatNumber(dataSummary.tot_disetujui)+' Surat'" :color="'#3BAFDA'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Surat Ditolak'" :persen="32.47" :content="formatNumber(dataSummary.tot_ditolak)+' Surat'" :color="'#F76197'" />
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" >
                    <StatisticRadial :title="'Total Surat Pending'" :persen="6.03" :content="formatNumber(dataSummary.tot_pending)+' Surat'" :color="'#98A5AD'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" sm="12" lg="12">
                    <v-card class="card pa-3" >
                        <h2 class="judul-nl font-weight-bold">Filter Data</h2>
                        <v-row class="mt-1">
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsKecamatan"
                                item-text="nama"
                                item-value="id_kec"
                                v-model="id_kec"                
                                @change="getKelurahan()"
                                label="--Semua Kecamatan--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsDesa"
                                item-text="nama_kel"
                                item-value="nama_kel"
                                v-model="nama_kel"
                                label="--Semua Desa--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.minggu"
                                label="--Minggu Ke---"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.bulan"
                                label="--Januari--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <v-select class="radius-25"  style="height:50px !important;"
                                :items="itemsFilter.triwulan"
                                label="--Triwulan--"
                                solo outlined dense
                                ></v-select>
                            </v-col>
                            <v-col cols="6" md="2" lg="2" sm="6">
                                <div class="fp-button">
                                    <v-btn class="radius-25"
                                    color="#6CBCF1"
                                    dark
                                    v-on:click="showLoading()"
                                    >
                                        Filter
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="12" xs="12" lg="4">
                    <v-card class="card pa-3" id="acuan" >
                        <h2 class="judul font-weight-bold">Statistik Pengajuan</h2>
                        <GeneralChart v-if="series.length" type="line" :series="series" :chartOptions="chartOptions" :height="350" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" sm="12" xs="12" lg="4" id="chart-general">
                    <v-card class="card pa-3" >
                        <h2 class="judul font-weight-bold">Statistik Persetujuan/Jenis</h2>
                        <ColumnChart :series="seriesColumn" :chartOptions="chartOptionsColumn" />
                    </v-card>
                </v-col>    
                <v-col cols="12" md="4" sm="12" xs="12" lg="4">
                    <FusionChart v-if="loadMount && series.length"
                    title="Statistik Pengajuan/Jenis" 
                    :type="type"
                    dataformat="'json'"
                    :dataSource="dataSource" />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <v-card class="card pa-3" >
                        <v-row class="p-1">
                            <v-col cols="12" sm="12" md="6">
                                <h2 class="font-weight-bold flex justify-items-end">Riwayat Pengajuan Surat</h2>
                                <p class="text-gray-400 text-xs p-0 m-0 " style="margin-top:5px !important">*"(tabel riwayat pengajuan surat, terbaru sampai terlama )"</p>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="flex justify-end">
                                <div class="table-search">
                                    <input type="text" v-model="search" placeholder="Cari Nama Penduduk" />
                                    <div class="icon-search" style="right:30px">
                                        <v-icon color="#BEBEBE">mdi-magnify</v-icon>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-simple-table class="mt-2" :dark="$vuetify.theme.dark"
                                fixed-header
                            >
                                <template v-slot:default>
                                <thead>
                                    <tr class="head-table-sft">
                                        <th>
                                            Nomor
                                        </th>
                                        <th class="text-left">
                                            Nama Surat
                                        </th>   
                                        <th class="text-left">
                                            Nama 
                                        </th>                                    
                                        <th class="text-left">
                                            NIK
                                        </th>                            
                                        <th class="text-left">
                                            Desa
                                        </th>
                                        <th class="text-left">
                                            Status
                                        </th>
                                        <th class="text-left">
                                            Tanggal
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, idx) in dataFilter"
                                    :key="idx"
                                    >
                                        <td>{{ idx+1 }}</td>
                                        <td>
                                            <v-icon color="#2c98e0">mdi-mail</v-icon> 
                                            {{item.name}}
                                        </td>
                                        <td><span class="font-medium text-blue-400">{{item.untuk.toUpperCase()}}</span></td>
                                        <td>{{item.no}}</td>
                                        <td>{{item.desa}}</td>
                                        <td v-if="item.status == 'Disetujui'" class="text-green-500">{{item.status}}</td>
                                        <td v-else class="text-red-500">{{item.status}}</td>
                                        <td style="white-space: nowrap;">{{item.tanggal}}</td>
                                    </tr>
                                    <tr v-if="dataFilter.length < 1">
                                        <td colspan="8" class="text-center">Tidak Ada Data</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import StatisticRadial from '@/components/StatisticRadial'
import GeneralChart from '@/components/charts/GeneralChart'
import ColumnChart from '@/components/charts/ColumnCharts'
import FusionChart from '@/components/charts/FusionChart'
import loading from '@/components/loading';
export default {
    name:'PelayananSurat',
    components:{
        StatisticRadial,
        GeneralChart,
        ColumnChart,
        FusionChart,
        loading
    },
    data(){
        return {
            itemsKecamatan: [],
            itemsDesa: [],
            id_kec: '',
            nama_kel: '',
            search:null,
            dataSummary:{
                tot_pengajuan: 0,
                tot_disetujui: 0,
                tot_ditolak: 0,
                tot_pending: 0
            },
            itemsFilter:{
                kec : [],
                desa:[],
                minggu:[],
                bulan:[],
                triwulan:[]
            },
            dataTable:[],
            series: [
            ],
            chartOptions: {
                chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                curve: 'straight'
                },
                grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
                },
                xaxis: {
                    categories: []
                }
            },
            seriesColumn: [],
            chartOptionsColumn: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    },
                },
                theme: {
                    mode: 'light', 
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return "$ " + val + " thousands"
                        }
                    }
                }
            },
            dataSource : {
                chart: {
                    showvalues: "1",
                    showpercentintooltip: "0",
                    numberprefix: "",
                    enablemultislicing: "1",
                    theme: "fusion"
                },
                data: []
            },
            type: "pie3d",
            loadMount:false
        }
    },
    computed:{
        dataFilter(){
            if(!this.search){
                return this.dataTable;
            }
            return this.dataTable.filter((data) =>{
                return data.untuk.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    methods:{
        showLoading() {
            this.$refs.loading.isLoading = true;
            setTimeout(() => {
                this.$refs.loading.isLoading = false;    
            }, 3000);
        },
        getSummary(){
          this.$http.get('/json/pelayanan-surat/summary.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSummary = res.data.dataSummary;
            this.itemsFilter = res.data.dataFilter
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPengajuan(){
          this.$http.get('/json/pelayanan-surat/pengajuan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.series = res.data.series;
            this.chartOptions.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPersetujuan(){
          this.$http.get('/json/pelayanan-surat/persetujuan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.seriesColumn = res.data.series;
            this.chartOptionsColumn.xaxis.categories = res.data.labels;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getPengajuanJenis(){
          this.$http.get('/json/pelayanan-surat/pengajuan-jenis.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataSource.data = res.data.data;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getSurat(){
          this.$http.get('/json/pelayanan-surat/riwayat-pengajuan-surat.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.dataTable = res.data.data;
            var banyakData = this.dataTable.length;
            let ini = this;
            for (let i = 0; i < banyakData; i++) {
                var date = new Date(this.dataTable[i].tanggal)
                var bulan = ("0" + (date.getMonth() + 1)).slice(-2);
                var tanggal = ("0" + date.getDate()).slice(-2);
                var tahun = date.getFullYear();
                ini.dataTable[i].tanggal = tanggal + '-' + bulan + '-' + tahun;
            }
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        
        getKecamatan() {
          this.$http.get('/json/filter/kecamatan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            this.itemsKecamatan = res.data.kecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        getKelurahan() {
          this.$http.get('/json/filter/kelurahan.json',{
              headers: {
                  "Accept": "application/json"
              }
          })
          .then((res) => { 
            var id_kec = this.id_kec;
            var semuaKelurahan = res.data.kelurahan;
            var kelurahanPerKecamatan = semuaKelurahan.filter(function(i) {
              return i.id_kec === id_kec;
            })
            this.itemsDesa = kelurahanPerKecamatan;
          }
          , error   => { 
            this.$notify({
              group: 'foo',
              title: 'Error',
              text: error.message
            });
          })
        },
        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    created(){
        this.getSummary();
        this.getPengajuan();
        this.getPersetujuan();
        this.getPengajuanJenis();
        this.getSurat();
        this.getKecamatan();
    },
    mounted(){
        this.loadMount = true;
    }
    
}
</script>