import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store.js'

import Penduduk from './views/pages/Penduduk'
import About from './views/pages/About'
import SFT from './views/pages/SFT'
import PelayananSurat from './views/pages/PelayananSurat'
import Aparatur from './views/pages/Aparatur'
import Siskudes from './views/pages/Siskudes'
import UMKM from './views/pages/UMKM'
import Login from './views/pages/Login'
import Preview from './views/pages/Preview'
import err404 from './views/pages/404'
// import Login from './views/pages/Login'

Vue.use(VueRouter)
const routes = [
  // {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    // path: "",
    // component: () => import("./layouts/Main.vue"),
    // children: [
      { path: '*', component: err404 },
      { 
        path: '/', 
        meta: {
              authRequired: true
        },
        component: Penduduk 
      },
      { path: '/penduduk', 
        meta: {
              authRequired: true
        },
        component: Penduduk 
      },
      { 
        path: '/about', 
        component: About,
        meta: {
              authRequired: true
        },
      },
      { 
        path: '/sft', 
        meta: {
              authRequired: true
        },
        component: SFT 
      },
      { 
        path: '/pelayanan-surat', 
        component: PelayananSurat,
        meta: {
              authRequired: true
        },
      },
      { 
        path: '/aparatur-desa', 
        component: Aparatur,
        meta: {
              authRequired: true
        },
      },
      { 
        path: '/siskudes', 
        component: Siskudes,
        meta: {
              authRequired: true
        },
       },
      {
        path: '/umkm', 
        component: UMKM,
        meta: {
                authRequired: true
        }, 
      },
      { path: '/login', component: Login },
      { 
        path: '/preview', 
        meta: {
              authRequired: true
        },
        component: Preview 
      },
  //   ]
  // },
  // =============================================================================
  // SINGLE LAYOUT ROUTES
  // =============================================================================
  // { path: '/login', component: Login },
]


const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

// function guardMyroute(to, from, next)
// {
//   var isAuthenticated= false;
//   //this is just an example. You will have to find a better or 
//   // centralised way to handle you localstorage data handling 
//   if(store.state.user == true)
//     isAuthenticated = true;
//   else
//     isAuthenticated= false;
    
//   if(isAuthenticated) 
//   {
//     next(); // allow to enter route
//   } 
//   else
//   {
//     // next('/login'); // go to '/login';
//     router.push('/login')
//   }
// }
router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    // console.log(localStorage.getItem('loggedIn'))
    if(localStorage.getItem('loggedIn') === 'true'){
      next();
      return;
    }
    router.push({ path: "/login", query: { to: to.path } });
  }
  if(to.path === '/login'){
    if(localStorage.getItem('loggedIn') === 'true'){
      router.push({ path: "/" });
    }
  }
  next();
})
export default router


