import Vue from 'vue'
import App from './App.vue'

import router from './router'
import vuetify from '@/plugins/vuetify'
import axios from './axios.js'
Vue.prototype.$http = axios
import Storage from 'vue-ls';
const options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};
Vue.use(Storage, options);

// notif
import Notifications from 'vue-notification'
Vue.use(Notifications)

// Apex chart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// firebase
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';
import firebaseConfig from './firebase.js'
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});
// Vuex Store
import store from './store/store'

//tailwind
import '@/assets/css/tailwind.css'

// v select
// import vSelect from 'vue-select'
// Vue.component('v-select', vSelect)
// import 'vue-select/dist/vue-select.css';

// fusion chart
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import SunBurst from 'fusioncharts/fusioncharts.powercharts';
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import FusionThemeZune from 'fusioncharts/themes/fusioncharts.theme.zune';
import FusionThemeCandy from 'fusioncharts/themes/fusioncharts.theme.candy';
import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);
Vue.use(VueFusionCharts, FusionCharts, Charts, SunBurst, FusionTheme, FusionThemeZune, FusionThemeCandy);
import "swiper/dist/css/swiper.css";
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')


