<template>
  <div id="app">
    <v-app>
      <notifications group="foo" />
      <div id="loaded" v-show="cl" class="load_construct">
        <v-progress-circular
          :size="200" class="construct_animate"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <nav v-if="toolbar" class="navbar">
          <v-app-bar app :dark="$vuetify.theme.dark">
              <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="openMenu()"></v-app-bar-nav-icon>
              <v-spacer></v-spacer>
              <!-- <v-btn depressed fab plain>
                  <v-icon :color=" $vuetify.theme.dark ? 'primary' : 'grey'">mdi-bell</v-icon>
              </v-btn> -->
              <div class="pt-5">
                  <v-switch inset  v-model="$vuetify.theme.dark"  color="blue" :append-icon=" $vuetify.theme.dark ? 'mdi-brightness-3' : 'mdi-weather-sunny'"></v-switch>
              </div>
              <v-btn depressed fab plain @click.stop="logout">
                <v-icon :color=" $vuetify.theme.dark ? 'primary' : 'grey'">mdi-exit-to-app</v-icon>
              </v-btn>
          </v-app-bar>
      </nav>
      <v-navigation-drawer v-if="toolbar" v-model="drawer"
        fixed
        :permanent="$vuetify.breakpoint.mdAndUp">
        <div class="grid">
          <img class="logo-app" src="/images/icons/logo.png" alt="Logo">
        </div>
          <v-list flat class="menu">
              <v-list-item v-for="item in items" :key="item.menu" route :to="item.route" v-model="selectedItem" color="primary">
                  <v-list-item-icon class="icon">
                      <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="nama-menu">
                      <v-list-item-title>{{ item.menu }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </v-list>

      </v-navigation-drawer>
      <div class="content" id="content-responsive"> 
        <v-main>
          <router-view></router-view>
        </v-main>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
// import Navbar from '@/components/navbar'
// import SideBar from '@/components/sidebar'

export default {
  name: 'App',
  components: {
    // Navbar,
    // SideBar 
  },
  data() {
     return {
       cl:true,
       window: {
            width: 0,
            height: 0
        },
        toolbar: false,
        drawer: null,
        selectedItem: 1,
        items: [
            { menu: 'Penduduk', icon: 'mdi-home-city', route: '/' },
            { menu: 'Aparatur Desa', icon: 'mdi-account', route: '/aparatur-desa' },
            { menu: 'Pelayanan Surat', icon: 'mdi-account-group-outline', route: '/pelayanan-surat' },
            { menu: 'UMKM', icon: 'mdi-home-city', route: '/umkm' },
            { menu: 'SFT', icon: 'mdi-account', route: '/sft' },
            // { menu: 'Bumdes', icon: 'mdi-account-group-outline', route: '/bumdes' },
            // { menu: 'Wisata', icon: 'mdi-account', route: '/wisata' },
            { menu: 'Siskeudes', icon: 'mdi-account-group-outline', route: '/siskudes' },
        ],
     }
  },
  methods:{
    cekPage(){
      if(this.toolbar == false){
        document.getElementById('content-responsive').className = 'content-full';
      }
      setTimeout(() =>{
        this.cl = false;
      },1000)
    },
    onPage(){
      let route = this.$route.fullPath;
       switch (route) {
            case '/umkm':
                this.toolbar = true;
                break;
            case '/':
                this.toolbar = true;
                break;
            case '/penduduk':
                this.toolbar = true;
                break;
            case '/sft':
                this.toolbar = true;
                break;
            case '/aparatur-desa':
                this.toolbar = true;
                break;
            case '/siskudes':
                this.toolbar = true;
                break;
            case '/pelayanan-surat':
                this.toolbar = true;
                break;
            default:
                this.toolbar = false;
        }
    },
    openMenu(){
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.drawer = true;
      }, 100)
    },
    logout(){
        firebase.auth().signOut().then(() => {
            firebase.auth().onAuthStateChanged(() => {
            this.$router.push('/login')
            })
        })
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if(this.window.width < 960){
        document.getElementById('content-responsive').className = 'content-full';
      }else{
        document.getElementById('content-responsive').className = 'content';
      }
      this.cekPage()
    }
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.onPage();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    this.$store.dispatch("initTheme");
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
    ...mapGetters({
        user: "user"
      })
  },
  watch: {
    theme(newTheme, oldTheme) {
      newTheme === "light"
        ? document.body.classList.remove("dark")
        : document.body.classList.add("dark");
    },
    $route (to, from){
        this.onPage();
    }
  },
  mounted(){
    this.handleResize();
    this.cekPage();
    this.$store.dispatch('toggleMnav', window.innerWidth)
  },
}
</script>

<style>
    @import 'assets/css/custom.css';
    .load_construct{
      position: fixed;
      background: #fff;
      z-index: 9999999;
      width: 100vw;
      height: 100vh;
    }
    .construct_animate{
      position: fixed;
      z-index: 99999999;
      width: 100vw;
      height: 100vh;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -100px;
    }
    .content{
      margin-left: 256px; /* 959 has hide */ 
      display:inline;
      /* background-color: #eee; */
    }
    .content-full{
      /* margin-left: 256px; 959 has hide  */
      display:inline;
      /* background-color: #eee; */
    }
    .profil{
        padding:10px !important;
    }

    .menu{
        padding:20px !important;
    }

    .menu .icon{
        margin-right: 10px !important
    }
    .menu .nama-menu{
        padding-left: 5px !important;
        font-size: 12px !important;
    }

    .navbar {
      z-index: 9999;
    }
</style>
