<template>
    <div id="login">
        <v-row style="margin:0px !important">
            <v-col class="login-left" cols="12" xs="12" sm="12" md="4">
            </v-col>
            <v-col class="login-right" cols="12" xs="12" sm="12" md="8">
                <div class="lbr-bg">
                </div>
            </v-col>
        </v-row>
        <transition name="view">
          <div class="preview" v-if="show">
              <div class="flex justify-end p-help">
                  <a class="text-xs" href="#">Butuh Bantuan ?</a>
              </div>
              <h1>
                  Pilih Dashboard Kegiatan
              </h1>
              <div class="preview-slide">
                  <swiper class="swiper" :options="swiperOption">
                      <swiper-slide v-for="x in dataSlide" :key="x.name" class="flex justify-center items-center">
                          <div class="slide-preview" @click="onClick(x.url)">
                              <img class="w-full" :src="x.img" alt="">
                              <h3 class="text-gray-500 mt-5 text-sm font-semibold">{{x.name}}</h3>
                          </div>
                      </swiper-slide>
                      <div class="swiper-button-prev" slot="button-prev">
                      </div>
                      <div class="swiper-button-next" slot="button-next">
                      </div>
                  </swiper>
              </div>
          </div>
        </transition>
    </div>
</template>
<script>

export default {
    name:'Preview',
    data() {
      return {
        dataSlide:[
          {
            name:'Halaman Penduduk',
            img: '/images/pages/penduduk.png',
            url:'/'
          },
          {
            name:'Halaman Aparat Desa',
            img: '/images/pages/aparat.png',
            url:'/aparatur-desa'
          },
          {
            name:'Halaman Pelayanan Surat Desa',
            img: '/images/pages/pelayanan-surat.png',
            url:'/pelayanan-surat'
          },
          {
            name:'Halaman UMKM',
            img: '/images/pages/umkm.png',
            url:'/umkm'
          },
          {
            name:'Halaman Social Fund Transfer',
            img: '/images/pages/sft.png',
            url:'/sft'
          },
          {
            name:'Halaman Siskudes',
            img: '/images/pages/siskudes.png',
            url:'/siskudes'
          }
        ],
        show:false,
        swiperOption: {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows : true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    },
    methods:{
      onClick(val){
        window.location.href = val;
      }
    },
    mounted(){
      setTimeout(()=>{
        this.show = true;
      },200)
    }
}
</script>

<style scoped>
  .view-leave-active {
      transition: opacity 0.5s ease-in-out, transform 0.5s ease;
  }

  .view-enter-active {
      transition: opacity 0.5s ease-in-out, transform 0.5s ease;
      transition-delay: 0.5s;
  }

  .view-enter, .view-leave-to {
      opacity: 0;
  }

  .view-enter-to, .view-leave {
      opacity: 1;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .swiper {
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #e4e4e415;
    background-position: center;
    background-size: cover;
    color: #FFF;
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    width: 70px !important;
    height: 70px !important;
    background-image: url('/images/icons/arrow-left.svg') !important;
    background-size: 70px 70px !important;
    margin-top: -35px !important;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    background-image: url('/images/icons/arrow-right.svg') !important;
    width: 70px !important;
    height: 70px !important;
    background-size: 70px 70px !important;
    margin-top: -35px !important;
    outline: none;
  }
</style>